/*
|----------------------------------------------------------------------
|       Body
|----------------------------------------------------------------------
*/

#root {
	padding-top: 8rem;
	overflow: initial;
}

/*
|----------------------------------------------------------------------
|       Header
|----------------------------------------------------------------------
*/

header {
	@include pos($pos: fixed, $b: initial, $z: 30);
	height: 8rem;
	background: $light;
	-webkit-box-shadow: $shadow;
	box-shadow: $shadow;
	@include trans;
	.toggle {
		@include ipad {
			@include flex;
		}
	}
}

.logo {
	position: relative;
	@include flex(center);
	width: 100%;
	max-width: 8rem;
	height: 6rem;
	> a {
		display: block;
		width: auto;
		max-width: 100%;
		height: 100%;
		> img {
			height: 100%;
			@include object(contain, left center);
			@include trans;
		}
	}
	header & {
		float: left;
		max-width: 5.2rem;
		height: 7rem;
		margin-top: 0.5rem;
		@include trans;
		@include ipad_sm {
			@include pos($z: 5);
			float: none;
			margin: auto;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
		}
		> a {
			width: 100%;
			> img {
				-o-object-position: center;
				object-position: center;
			}
		}
	}
	header.fixed & {
		height: 8rem;
	}
	footer & {
		max-width: 14rem;
		height: auto;
	}
}

/*
|----------------------------------------------------------------------
|       Navigation
|----------------------------------------------------------------------
*/

nav {
	position: relative;
	@include flex(center, flex-end);
	height: 8rem;
	margin-left: 20rem;
	@include ipad_sm {
		margin: 0;
	}
	.social_links {
		@include pos($l: null);
		margin: auto;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		@include mobile_md {
			display: none;
		}
	}
}

#nav {
	@include flex($justify: space-between);
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	@include trans;
	@include ipad_sm {
		@include pos($pos: fixed, $t: 8rem);
		@include flex($flow: wrap);
		-ms-flex-line-pack: justify;
		align-content: space-between;
		width: auto;
		background: $light;
		margin: 0;
		padding: 2rem 5rem;
		border-top: 0.2rem solid rgba($black, 0.1);
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		overflow: auto;
		z-index: 3;
		&.active {
			opacity: 1;
			visibility: visible;
			pointer-events: auto;
		}
	}
	> ul {
		@include flex(center, center);
		@include list;
		margin: 0 -2rem;
		@include ipad_sm {
			width: 100%;
			-ms-flex-flow: wrap;
			flex-flow: wrap;
			margin: 0;
		}
		> li {
			position: relative;
			padding: 0 2rem;
			@include ipad_sm {
				width: 100%;
				padding: 0;
				text-align: center;
				.site_btn {
					margin-top: 0.5rem;
				}
			}
			> a:not(.site_btn) {
				@include flex(center, center);
				display: -webkit-inline-box;
				display: -ms-inline-flexbox;
				display: inline-flex;
				height: 4rem;
				color: $black;
				text-align: center;
				&:hover,
				&.active {
					color: $color !important;
				}
			}
			> .sub {
				position: absolute;
				top: 100%;
				left: 2rem;
				width: 20rem;
				background: $light;
				text-align: left;
				padding: 1rem 0;
				margin-top: 1rem;
				visibility: hidden;
				opacity: 0;
				border-radius: 0.6rem;
				-webkit-transition: none;
				transition: none;
				-webkit-box-shadow: $shadow;
				box-shadow: $shadow;
				pointer-events: none;
				@include trans;
				overflow: hidden;
				@include ipad_sm {
					position: static;
					display: none;
					width: 100%;
					max-height: none;
					background: transparent;
					visibility: visible;
					opacity: 1;
					text-align: center;
					padding: 0;
					margin: 0 auto !important;
					border: 0;
					border-radius: 0;
					-webkit-transition: none;
					transition: none;
					-webkit-box-shadow: none;
					box-shadow: none;
				}
				&.active {
					display: block;
				}
				> li {
					display: block;
					> a {
						display: block;
						color: $black;
						padding: 1rem 2rem;
						@include ipad_sm {
							@include flex($type: inline-flex);
						}
						&:hover {
							background: rgba($color, 0.05);
							color: $color;
						}
					}
				}
			}
			&:hover > .sub {
				visibility: visible;
				opacity: 1;
				margin-top: 0;
				pointer-events: auto;
			}
			&.drop > a {
				position: relative;
				&:after {
					content: "";
					@include chev;
					width: 1rem;
					height: 1rem;
					color: inherit;
					margin-left: 0.6rem;
				}
			}
		}
	}
	> #lst {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}
	> #cta {
		margin-left: 2rem;
		@include ipad_sm {
			margin: auto 0 0;
		}
	}
}

/*
|----------------------------------------------------------------------
|       Footer
|----------------------------------------------------------------------
*/

footer {
	position: relative;
	background: $black2;
	color: $light;
	padding: 8rem 0 6rem;
	margin-top: auto;
	overflow: hidden;
	.contain {
		z-index: 3;
	}
	a {
		color: rgba($light, 0.7);
		&:hover {
			// color: $color;
			color: $dark_gray;
		}
	}
	.main_row {
		> .col {
			-webkit-box-flex: 1;
			-ms-flex: auto;
			flex: auto;
			&:nth-last-child(1) {
				width: 40%;
			}
			@include ipad_sm {
				width: 100%;
			}
		}
	}
	h5 {
		position: relative;
		margin-bottom: 3rem;
		@include ipad_sm {
			margin-bottom: 1.5rem;
		}
		&.drop {
			@include ipad_sm {
				cursor: pointer;
				margin-bottom: 0;
			}
			&:after {
				position: absolute;
				top: 0.6rem;
				right: 0;
				@include chev;
				width: 1rem;
				height: 1rem;
				@include ipad_sm {
					content: "";
				}
			}
			@include ipad_sm {
				& + .list {
					display: none;
					margin-top: 1.5rem;
				}
			}
		}
	}
	.list {
		@include list;
		> li {
			display: block;
			&:not(:nth-last-child(1)) {
				margin-bottom: 1rem;
			}
		}
	}
	.copyright {
		margin-top: 2rem;
		@include ipad_sm {
			text-align: center;
		}
		> a {
			// color: $color;
			color: $light;
			text-decoration: underline;
		}
	}
	.subscribe {
		@include flex(center, space-between);
		// padding-bottom: 3rem;
		margin-bottom: 6rem;
		// border-bottom: 0.1rem solid rgba($light, 0.2);
		@include ipad_sm {
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-flow: column;
			flex-flow: column;
		}
		> span {
			font-size: 2rem;
			// font-weight: 400;
			max-width: 32rem;
			@include ipad_sm {
				margin-bottom: 1.5rem;
				text-align: center;
			}
		}
		form {
			position: relative;
			width: 100%;
			max-width: 40rem;
			.input {
				height: 6rem;
				color: $black;
				padding-right: 6rem;
			}
			button {
				@include pos;
				left: initial;
				width: 3rem;
				height: 3rem;
				background: $color;
				padding: 0.6rem;
				margin: auto;
				margin-right: 1.2rem;
				border: 0;
				border-radius: 0.6rem;
				&:hover {
					background: $black;
				}
				> img {
					-webkit-filter: brightness(0) invert(1);
					filter: brightness(0) invert(1);
				}
			}
		}
	}
	.social_links {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		@include ipad_sm {
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
		}
	}
}

.social_links {
	@include flex($flow: wrap);
	@include list;
	gap: 0.5rem;
	li {
		a {
			@include flex(center, center);
			display: -webkit-box !important;
			display: -ms-flexbox !important;
			display: flex !important;
			width: 3.6rem;
			min-width: 3.6rem;
			height: 3.6rem;
			// background: $color;
			background: rgba($light, 0.2);
			padding: 1rem;
			border-radius: 75%;
			&:hover {
				background: $dark_gray;
			}
			img {
				-webkit-filter: brightness(0) invert(1);
				filter: brightness(0) invert(1);
			}
		}
	}
}

/*
|----------------------------------------------------------------------
|       Banner
|----------------------------------------------------------------------
*/

#banner {
	@include bg(cover);
	padding: 0;
	&:before {
		@include overlay($black, 0.5);
	}
	.contain {
		z-index: 3;
	}
	.flex_blk {
		@include flex(center);
		min-height: 56rem;
		padding: 8rem 0;
		@include ipad_sm {
			// min-height: initial;
		}
	}
	.content {
		max-width: 54rem;
		color: $light;
		margin: 0 auto;
		h1 {
			font-size: 4.8rem;
			margin-bottom: 3.5rem;
			line-height: 1.1;
			text-shadow: 0.5rem 0.5rem 0.5rem rgba($black, 0.3);
			@include ipad_sm {
				font-size: 4.8rem;
			}
			@include mobile_md {
				font-size: 4rem;
			}
		}
		p {
			color: $light_gray;
			font-size: 1.8rem;
			text-shadow: 0.5rem 0.5rem 0.5rem rgba($black, 0.2);
			@include mobile_md {
				font-size: 1.6rem;
			}
		}
	}
	> video {
		@include pos;
		@include object(cover);
	}
}

/*
|----------------------------------------------------------------------
|       Since
|----------------------------------------------------------------------
*/

#since {
	.main_row {
		> .col {
			-webkit-box-flex: 1;
			-ms-flex: 1;
			flex: 1;
			@include ipad_sm {
				-webkit-box-flex: 0;
				-ms-flex: none;
				flex: none;
				width: 50%;
			}
			&.col1 {
				width: 40%;
				@include ipad_sm {
					width: 100%;
				}
			}
		}
	}
	figure {
		@include img($pb: 100%);
		width: 100%;
		margin: 0;
		> img {
			@include pos;
			@include object;
		}
	}
}

/*
|----------------------------------------------------------------------
|       Strip
|----------------------------------------------------------------------
*/

#strip {
	.main_row {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		> .col1 {
			width: 40%;
		}
		> .col2 {
			width: 60%;
		}
		@include ipad_sm {
			> .col {
				width: 100%;
			}
		}
	}
	.title {
		text-align: right;
		@include ipad_sm {
			text-align: center;
		}
	}
	figure {
		margin: 0;
	}
}

/*
|----------------------------------------------------------------------
|       Works
|----------------------------------------------------------------------
*/

#works {
	.main_row {
		> .col {
			width: 25%;
			@include ipad_sm {
				width: 50%;
			}
			@include mobile_md {
				width: 100%;
			}
		}
	}
	.content {
		max-width: 50rem;
		margin: 0 auto 4rem;
	}
}

/*
|----------------------------------------------------------------------
|       Intro
|----------------------------------------------------------------------
*/

#intro {
	.flex_row {
		> .col1 {
			width: 38%;
		}
		> .col2 {
			width: 62%;
		}
		@include ipad_sm {
			> .col {
				width: 100%;
			}
		}
	}
	.img {
		@include img;
	}
	& + #serve {
		padding-top: 0;
	}
}

/*
|----------------------------------------------------------------------
|       Serve
|----------------------------------------------------------------------
*/

#serve {
	.flex_row {
		> .col {
			width: 33.3334%;
			@include ipad_sm {
				width: 100%;
			}
		}
	}
	.content {
		max-width: 40rem;
		margin: 0 auto 4rem;
	}
}

.serve_blk {
	.fig {
		@include img($pb: 60%);
		> img {
			@include object($fill: true);
		}
	}
	.txt {
		padding-top: 1.5rem;
	}
}

/*
|----------------------------------------------------------------------
|       Choose
|----------------------------------------------------------------------
*/

#choose {
	background: $light_white;
	.flex_row {
		> .col {
			width: 33.3334%;
			@include ipad_sm {
				width: 50%;
			}
			@include mobile_md {
				width: 100%;
			}
		}
	}
	.content {
		max-width: 70rem;
		margin: 0 auto 4rem;
	}
	& + #folio {
		padding-top: 4rem;
	}
}

.choose_blk {
	@include block;
	.ico {
		width: 8rem;
		min-width: 8rem;
		height: 8rem;
		background: rgba($color, 0.1);
		margin: 0 auto 2rem;
		padding: 1rem;
		border-radius: 1rem;
	}
	.txt {
		h4 {
			> strong {
				display: block;
				color: $color;
			}
		}
	}
}

/*
|----------------------------------------------------------------------
|       Assets
|----------------------------------------------------------------------
*/

#assets {
	@include bg(cover);
	padding: 0;
	&:before {
		@include overlay($black2, 0.4);
	}
	.contain {
		z-index: 3;
	}
	> video {
		@include pos;
		@include object(cover);
		z-index: 0;
	}
	.flex_blk {
		@include flex(center);
		min-height: calc(100vh - 8rem);
		padding: 8rem 0;
	}
	.content {
		max-width: 66rem;
		color: $light;
		margin: 0 auto;
		h1 {
			font-size: 4.6rem;
			@include mobile {
				font-size: 4rem;
			}
			@include mobile_md {
				font-size: 3.6rem;
			}
		}
		p {
			color: inherit;
		}
	}
	.play_btn {
		width: 8rem;
		height: 8rem;
		background: rgba($dark, 0.1);
		margin: auto;
		padding: 0;
		border: 0;
		border-radius: 75%;
		z-index: 2;
	}
	> iframe {
		@include pos;
		display: block;
		width: 100%;
		height: 100%;
		-o-object-fit: fill;
		object-fit: fill;
		z-index: -1;
	}
}

/*
|----------------------------------------------------------------------
|       Folio
|----------------------------------------------------------------------
*/

#folio {
	background: $light_white;
	.flex_row {
		> .col1 {
			width: 30%;
		}
		> .col2 {
			width: 70%;
		}
		@include ipad_sm {
			> .col {
				width: 100%;
			}
		}
	}
	.content {
		@include ipad_sm {
			text-align: center;
		}
	}
	.folio_blk {
		@include block;
		padding: 4rem;
		text-align: center;
		.ico {
			width: 10rem;
			min-width: 10rem;
			height: 10rem;
			margin: 0 auto 2rem;
			// border: 0.4rem solid $color;
		}
		.txt {
			h5 {
				color: $color;
				small {
					display: block;
					color: $dark_gray;
					font-size: 1.3rem;
					font-weight: 400;
					margin-top: 0.4rem;
				}
			}
		}
	}
	#owl-folio {
		width: auto;
		margin: -1.5rem;
		.owl-item {
			padding: 1.5rem;
		}
	}
}

/*
|----------------------------------------------------------------------
|       FAQ's
|----------------------------------------------------------------------
*/

#faq {
	background: $light_white;
	.contain {
		max-width: 76rem;
		z-index: 3;
	}
}

.faq_lst {
	&:not(:nth-last-child(1)) {
		margin-bottom: 4rem;
	}
	> .faq_blk {
		@include block($p: 2rem);
		margin-bottom: 2rem;
		@include trans;
		// &:nth-child(1) {
		// 	.txt {
		// 		display: block;
		// 	}
		// }
		&:nth-last-child(1) {
			margin-bottom: 0;
		}
		&.active {
			h5 {
				color: $color;
				&:after {
					background: $color;
					-webkit-clip-path: polygon(0 40%, 0 60%, 100% 60%, 100% 40%);
					clip-path: polygon(0 40%, 0 60%, 100% 60%, 100% 40%);
				}
			}
		}
		h5 {
			position: relative;
			padding: 0 2rem 0 0;
			margin: 0;
			text-transform: uppercase;
			cursor: pointer;
			@include trans;
			z-index: 2;
			&:after {
				content: "";
				@include pos(0.2rem, $l: null, $b: null);
				width: 1.2rem;
				height: 1.2rem;
				background: $black;
				-webkit-clip-path: polygon(0 40%, 0 60%, 40% 60%, 40% 100%, 60% 100%, 60% 60%, 100% 60%, 100% 40%, 60% 40%, 60% 0, 40% 0, 40% 40%);
				clip-path: polygon(0 40%, 0 60%, 40% 60%, 40% 100%, 60% 100%, 60% 60%, 100% 60%, 100% 40%, 60% 40%, 60% 0, 40% 0, 40% 40%);
				@include trans;
			}
			&:hover {
				color: $color;
			}
		}
		.txt {
			// display: none;
			padding: 1rem 0 0;
		}
	}
}

/*
|----------------------------------------------------------------------
|       Contact Us
|----------------------------------------------------------------------
*/

#contact {
	.content {
		max-width: 50rem;
		margin: 0 auto 4rem;
	}
	form {
		max-width: 64rem;
		@include block;
		background: $light_gray;
		margin: 0 auto;
	}
	.info_row {
		@include ipad_sm {
			width: calc(100% + 3rem);
			margin: -1.5rem;
			@include slider;
		}
		> .col {
			width: 33.3334%;
			@include ipad_sm {
				padding: 1.5rem;
			}
		}
		.inner {
			@include block;
			background: $light_gray;
			@include ipad_sm {
				min-width: 26rem;
			}
			.icon {
				width: 4rem;
				min-width: 4rem;
				height: 4rem;
				margin: 0 auto 1.5rem;
			}
		}
	}
	@at-root {
		#map_blk {
			@include img;
			height: 40rem;
			iframe {
				@include pos;
				width: 100%;
				height: 100%;
			}
		}
	}
}

/*
|----------------------------------------------------------------------
|       Team
|----------------------------------------------------------------------
*/

#team {
	.content {
		max-width: 76rem;
		margin: 0 auto 3rem;
	}
	.main_row {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		@include ipad_sm {
			@include slider;
		}
		> .col {
			width: 25%;
		}
	}
	.team_blk {
		max-width: 28rem;
		margin: 0 auto;
		@include ipad_sm {
			width: 24rem;
			min-width: 24rem;
		}
	}
}

.team_blk {
	text-align: center;
	// @include block($p: 2rem);
	figure {
		@include img;
		width: 20rem;
		min-width: 20rem;
		height: 20rem;
		margin: 0 auto;
		border-radius: 75%;
		overflow: hidden;
		> img {
			@include object($fill: true);
		}
	}
	.txt {
		padding: 2rem 0 0;
		margin: 0 0 auto;
		h5 {
			margin-bottom: 0.5rem;
		}
		.social_links {
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
		}
	}
}

/*
|----------------------------------------------------------------------
|       About us
|----------------------------------------------------------------------
*/

#paid {
	background: $color;
	.content {
		max-width: 70rem;
		color: $light;
		margin: 0 auto;
		p {
			color: inherit;
		}
	}
}

#affiliate {
	background: $light_white;
	.main_row {
		@include ipad_sm {
			@include slider;
		}
		> .col {
			width: 33.3334%;
		}
	}
	.content {
		max-width: 52rem;
		margin: 0 auto 4rem;
	}
	.aff_blk {
		@include block;
		@include ipad_sm {
			width: 28rem;
			min-width: 28rem;
		}
		.icon {
			width: 8rem;
			min-width: 8rem;
			height: 8rem;
			background: rgba($color, 0.1);
			margin: 0 0 2rem;
			padding: 1rem;
			border-radius: 1rem;
		}
	}
}

/*
|----------------------------------------------------------------------
|       Blog
|----------------------------------------------------------------------
*/

#blog {
	.main_row {
		> .col1 {
			width: 75%;
		}
		> .col2 {
			width: 25%;
		}
		@include ipad_sm {
			> .col {
				width: 100%;
			}
		}
	}
	.sub_row {
		> .col {
			width: 33.3334%;
			@include ipad_sm {
				width: 50%;
			}
			@include mobile {
				width: 100%;
			}
		}
	}
	.post_blk {
		padding-bottom: 120%;
	}
	.article_wrap {
		.article {
			@include flex($flow: row-reverse);
			// @include block;
			// padding: 2rem;
			&:not(:nth-last-child(1)):not(:nth-child(6)) {
				margin-bottom: 3rem;
			}
			&:nth-child(n + 7) {
				display: none;
			}
			.ico {
				width: 6rem;
				min-width: 6rem;
				height: 6rem;
				margin-left: 2rem;
				// border-radius: 1rem;
				overflow: hidden;
			}
			.txt {
				position: relative;
				width: 100%;
				h5 {
					display: -webkit-box;
					line-clamp: 2;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					margin-bottom: 0;
					overflow: hidden;
				}
			}
			.date {
				color: $dark_gray;
			}
		}
	}
}

/*
|----------------------------------------------------------------------
|       Brands
|----------------------------------------------------------------------
*/

#brands {
	.content {
		max-width: 64rem;
		margin: 0 auto 4rem;
	}
	.list {
		@include list;
		@include flex($justify: center, $flow: wrap);
		margin: -3rem -1.5rem;
		> li {
			width: 25%;
			padding: 3rem 1.5rem;
			@include ipad_sm {
				width: 33.3334%;
			}
			@include mobile_md {
				width: 50%;
			}
			.icon {
				display: block;
				width: 16rem;
				height: 6rem;
				background: $light_white;
				margin: auto;
				mix-blend-mode: darken;
				@include trans;
				&:hover {
					-webkit-filter: brightness(1) invert(0) grayscale(1);
					filter: brightness(1) invert(0) grayscale(1);
				}
			}
		}
	}
}

/*
|----------------------------------------------------------------------
|       Terms
|----------------------------------------------------------------------
*/

#terms {
	.blk {
		background: $light_gray;
	}
}

/*
|----------------------------------------------------------------------
|       Destinations
|----------------------------------------------------------------------
*/

#destination {
	// padding-top: 0.2rem;
	.main_row {
		> .col {
			width: 33.3334%;
			@include ipad_sm {
				width: 50%;
			}
			@include mobile_md {
				width: 100%;
			}
		}
	}
	.content {
		max-width: 50rem;
		margin: 0 auto 3rem;
	}
	#map_blk {
		height: calc(100vh - 8rem);
		margin-top: -8rem;
		margin-bottom: 6rem;
		-webkit-filter: invert(1) grayscale(1);
		filter: invert(1) grayscale(1);
	}
	.area_blk {
		padding-bottom: 140%;
	}
	& + #itinerary {
		padding-top: 0;
	}
}

/*
|----------------------------------------------------------------------
|       Overview
|----------------------------------------------------------------------
*/

#overview {
	.main_row {
		> .col {
			width: 50%;
			@include ipad_sm {
				width: 100%;
			}
		}
	}
	#map_blk {
		height: auto;
		-webkit-filter: invert(1) grayscale(1);
		filter: invert(1) grayscale(1);
		@include ipad_sm {
			height: 40rem;
		}
	}
	.fig {
		figure {
			margin: 0;
		}
	}
	.list_item {
		@include list;
		> li {
			@include flex(flex-start);
			counter-increment: custom;
			&:not(:nth-last-child(1)) {
				margin-bottom: 2rem;
			}
			&:before {
				content: counter(custom) " ";
				@include flex($type: inline-flex);
				font-weight: 500;
				margin-right: 2rem;
				opacity: 0.2;
				white-space: nowrap;
			}
		}
	}
	#country {
		padding-top: 8rem;
		figure {
			margin: 0;
		}
	}
}

/*
|----------------------------------------------------------------------
|       What
|----------------------------------------------------------------------
*/

#what {
	@include bg(cover);
	.main_row {
		@include ipad_sm {
			@include slider;
		}
		> .col {
			width: 33.3334%;
		}
	}
	.what_blk {
		@include ipad_sm {
			width: 30rem;
			min-width: 30rem;
		}
		figure {
			@include img($pb: 70%);
			margin: 0 0 1.5rem;
			> img {
				@include object($fill: true);
			}
		}
	}
}

/*
|----------------------------------------------------------------------
|       Experience
|----------------------------------------------------------------------
*/

#experience {
	.main_row {
		@include ipad_sm {
			@include slider;
		}
		> .col {
			width: 50%;
		}
	}
	.inner {
		@include ipad_sm {
			width: 30rem;
			min-width: 30rem;
		}
		figure {
			@include img($pb: 70%);
			margin: 0 0 1.5rem;
			> a {
				@include pos;
				> img {
					@include object;
				}
			}
		}
	}
}

/*
|----------------------------------------------------------------------
|       Enquire Block
|----------------------------------------------------------------------
*/

#enquire_blk {
	padding: 0;
	.inside {
		background: $black2;
		color: $light;
		padding: 8rem;
		@include mobile_md {
			padding: 8rem 4rem;
			margin: 0 -1.5rem;
		}
		.txt {
			max-width: 50rem;
			margin: auto;
		}
	}
}

/*
|----------------------------------------------------------------------
|       Inspire
|----------------------------------------------------------------------
*/

#inspire {
	@include bg(cover);
	.main_row {
		> .col {
			width: 50%;
			@include ipad_sm {
				width: 100%;
			}
		}
	}
	.review {
		@include block;
		.ico {
			width: 6rem;
			min-width: 6rem;
			height: 6rem;
			border-radius: 0.6rem;
			overflow: hidden;
		}
		.rating {
			@include flex($type: inline-flex);
			gap: 0.2rem;
			padding: 0.2rem 0.6rem;
			background: rgba($color, 0.2);
			border-radius: 0.4rem;
			.star {
				display: inline-block;
				width: 1.6rem;
				min-width: 1.6rem;
				height: 1.6rem;
				@include star;
				color: #ffd100;
			}
		}
	}
}

/*
|----------------------------------------------------------------------
|       Partners
|----------------------------------------------------------------------
*/

#partners {
	.content {
		max-width: 48rem;
		margin: 0 auto 3rem;
	}
	.list {
		@include list;
		@include flex($justify: center, $flow: wrap);
		margin: -2rem -1.5rem;
		> li {
			width: 25%;
			padding: 2rem 1.5rem;
			@include ipad_sm {
				width: 33.3334%;
			}
			@include mobile_md {
				width: 50%;
			}
			.ico {
				display: block;
				width: 16rem;
				height: 8rem;
				background: $light;
				margin: auto;
				mix-blend-mode: darken;
				@include trans;
				@include mobile_sm {
					width: 14rem;
					height: 6rem;
				}
				&:hover {
					-webkit-filter: brightness(1) invert(0) grayscale(1);
					filter: brightness(1) invert(0) grayscale(1);
				}
			}
		}
	}
}

/*
|----------------------------------------------------------------------
|       Tabs
|----------------------------------------------------------------------
*/

#tabs {
	@include pos($pos: sticky, $t: 8rem, $b: null, $z: 10);
	padding: 0;
	// margin-bottom: 8rem;
	ul {
		@include list;
		@include flex($justify: space-between);
		background: $black;
		@include ipad_sm {
			@include slider;
		}
		> li {
			> a {
				@include flex(center, center);
				height: 6rem;
				padding: 0 4rem;
				color: $light;
				text-transform: uppercase;
				white-space: nowrap;
				&:hover {
					color: $color;
				}
			}
		}
	}
}

/*
|----------------------------------------------------------------------
|       Trip
|----------------------------------------------------------------------
*/

#trip {
	padding-top: 0;
	.main_row {
		> .col {
			width: 50%;
			@include ipad_sm {
				width: 100%;
			}
		}
	}
	.sub_row {
		> .col {
			width: 50%;
			@include mobile_md {
				width: 100%;
			}
		}
	}
	.itinerary_blk {
		h3 {
			font-size: 2.2rem;
		}
	}
}

#owl-trip {
	figure {
		@include img;
		margin: 0;
		padding-bottom: 120%;
		> img {
			@include object($fill: true);
		}
	}
	.owl-dots {
		position: absolute;
	}
	@include ipad_sm {
		.owl-nav {
			.owl-prev {
				left: 0.5em;
			}
			.owl-next {
				right: 0.5em;
			}
		}
	}
}

/*
|----------------------------------------------------------------------
|       Reviews
|----------------------------------------------------------------------
*/

#reviews {
	background: $light_white;
	.main_row {
		> .col {
			width: 50%;
			@include ipad_sm {
				width: 100%;
			}
		}
	}
	.review {
		@include block;
		.ico {
			width: 6rem;
			min-width: 6rem;
			height: 6rem;
			border-radius: 0.6rem;
			overflow: hidden;
		}
		.rating {
			@include flex($type: inline-flex);
			gap: 0.2rem;
			// padding: 0.2rem 0.6rem;
			// background: rgba($color, 0.2);
			// border-radius: 0.4rem;
			.star {
				display: inline-block;
				width: 1.6rem;
				min-width: 1.6rem;
				height: 1.6rem;
				@include star;
				color: #ffd100;
			}
		}
	}
}

/*
|----------------------------------------------------------------------
|       Service
|----------------------------------------------------------------------
*/

#service {
	.flex_row {
		> .col1 {
			width: 54%;
		}
		> .col2 {
			width: 46%;
		}
		@include ipad_sm {
			> .col {
				width: 100%;
			}
		}
	}
	.img {
		@include img;
	}
	& + #serve {
		padding-top: 0;
	}
}
