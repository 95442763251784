/*
|----------------------------------------------------------------------
|       Variables
|----------------------------------------------------------------------
*/

$pix: px;
// $color: #0f50c2;
$color: #1f1f1f;
$light: #fff;
$black: #403e37;
$black2: #292722;
$dark: #000;
$dark_gray: #b0b0b0;
$light_white: #f5f6fa;
$light_gray: #eee;
$gray: #c4c4c4;
$green: #43b753;
$yellow: #ffcb00;
$red: #f31f34;
$blue: #5883ff;
$font: "Poppins",
	sans-serif;
// container width
$container: 120rem;
$container_sm: 100rem;
$container_fluid: 1920px;

$shadow: 0 1rem 4rem -0.3rem rgba($black, 0.1);
$shadow2: 0 0.7rem 1.5rem -0.5rem rgba($black, 0.08),
	0 -0.5rem 1rem -0.6rem rgba($black, 0.03);
$chevron: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="100px" height="100px" fill="%23000" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;"><polygon points="80.7,20 50,50.7 19.3,20 4.7,34.7 50,80 95.3,34.7 "/></svg>');
$info: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100px" height="100px" fill="%23fff" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;"><path d="M50,2.7C23.9,2.7,2.7,23.9,2.7,50S23.9,97.3,50,97.3S97.3,76.1,97.3,50S76.1,2.7,50,2.7z M49.8,75.6 c-2.7,0-4.8-2.2-4.8-4.8c0-2.7,2.2-4.8,4.8-4.8c2.7,0,4.8,2.2,4.8,4.8C54.7,73.4,52.5,75.6,49.8,75.6z M64,41 c-0.9,4.3-4.1,7.7-6.9,10.7c-0.3,0.4-0.7,0.7-1,1c-1.1,1.1-1.6,1.6-2,2.6c-0.5,1.1-0.7,2.5-0.5,3.9c0.2,2-1.3,3.7-3.3,3.9 c-0.1,0-0.2,0-0.3,0c-1.8,0-3.4-1.4-3.6-3.3c-0.2-2.7,0.1-5.1,1.1-7.4c1-2.2,2.2-3.5,3.5-4.8c0.3-0.3,0.6-0.6,0.9-0.9 c2.2-2.4,4.6-4.9,5.1-7.3c0.4-2.1-0.1-5-2.1-6.6c-2.5-2.1-6.5-1.5-8.9,0.3c-3.6,2.6-3.2,7.7-3.2,7.7c0.2,2-1.2,3.7-3.2,3.9 c-2,0.2-3.7-1.2-3.9-3.2c-0.3-3.4,0.6-10.2,6.1-14.2c4.7-3.4,12.2-4.5,17.7,0C63.9,31,64.9,36.9,64,41z"/></svg>');
$star: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100px" height="100px" fill="%23fff" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;"><path d="M99.9,38.6c-0.2-0.6-0.8-1.1-1.4-1.2l-32.4-4.7L51.6,3.4c-0.3-0.6-0.9-1-1.6-1s-1.3,0.4-1.6,1L33.9,32.7L1.5,37.4 c-0.7,0.1-1.2,0.6-1.4,1.2c-0.2,0.6,0,1.4,0.5,1.8L24,63.3l-5.5,32.3c-0.1,0.7,0.2,1.3,0.7,1.8c0.6,0.4,1.3,0.5,1.9,0.1l29-15.2 l29,15.2c0.3,0.1,0.5,0.2,0.8,0.2c0.4,0,0.7-0.1,1.1-0.3c0.6-0.4,0.8-1.1,0.7-1.8L76,63.3l23.4-22.8C99.9,40,100.1,39.3,99.9,38.6z" /></svg>');

/*
|----------------------------------------------------------------------
|       Mixins
|----------------------------------------------------------------------
*/

@mixin font-face($name, $path, $ext, $format, $weight) {
	$src: null;

	@font-face {
		font-family: quote($name);
		font-style: normal;
		font-weight: $weight;
		font-display: swap;
		src: append($src, url(quote($path + "." + $ext)) format(quote($format)));
	}
}

@include font-face("Poppins", "./assets/fonts/Poppins-Light", woff2, "woff", 300);
@include font-face("Poppins", "./assets/fonts/Poppins-Regular", woff2, "woff", 400);
@include font-face("Poppins", "./assets/fonts/Poppins-Medium", woff2, "woff", 500);
@include font-face("Poppins", "./assets/fonts/Poppins-SemiBold", woff2, "woff", 600);
@include font-face("Poppins", "./assets/fonts/Poppins-Bold", woff2, "woff", 700);

@mixin flex($align: null, $justify: null, $flow: null, $direction: null, $type: flex) {
	display: $type;
	-webkit-box-align: $align;
	-ms-flex-align: $align;
	align-items: $align;
	-webkit-box-pack: $justify;
	-ms-flex-pack: $justify;
	justify-content: $justify;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-flow: $flow;
	flex-flow: $flow;
	-ms-flex-direction: $direction;
	flex-direction: $direction;
}

@mixin pos($t: 0, $r: 0, $b: 0, $l: 0, $z: null, $pos: absolute) {
	position: $pos;
	top: $t;
	right: $r;
	bottom: $b;
	left: $l;
	z-index: $z;
}

@mixin overlay($color, $opacity) {
	content: "";
	@include pos($z: 1);
	background: $color;
	opacity: $opacity;
}

@mixin bg($size, $pos: center, $attach: null) {
	background-size: $size;
	background-position: $pos;
	background-repeat: no-repeat;
	background-attachment: $attach;
}

@mixin row($tb: 1.5rem, $lr: 1.5rem, $w: calc(100% + #{$lr * 2})) {
	width: $w;
	margin: (-($tb)) (-($lr));

	>* {
		padding: $tb $lr;
	}
}

@mixin fill {
	display: block;
	width: 100%;
	height: 100%;
}

@mixin nowrap {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

@mixin block($p: 2.5rem, $rad: 1rem) {
	position: relative;
	background: $light;
	padding: $p;
	border-radius: $rad;
	-webkit-box-shadow: $shadow2;
	box-shadow: $shadow2;
}

@mixin object($fit: cover, $pos: center, $fill: false) {
	@include fill;
	-o-object-fit: $fit;
	object-fit: $fit;
	-o-object-position: $pos;
	object-position: $pos;

	@if $fill {
		@include pos;
	}
}

@mixin chevron {
	background-image: $chevron;
	background-position: right center;
	background-repeat: no-repeat;
	background-size: 1rem;
}

@mixin list {
	list-style: none;
	padding: 0;
	margin: 0;
}

@mixin slider($p: null) {
	@include flex(initial, initial, nowrap);
	padding: $p;
	overflow: hidden;
	overflow-x: auto;

	&::-webkit-scrollbar {
		height: 0;
	}

	>.col {
		width: auto !important;
	}
}

@mixin img($pb: null) {
	@include block;
	padding: 0;
	padding-bottom: $pb;
	overflow: hidden;
}

@mixin trans {
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}

@mixin mask_image {
	-webkit-mask-size: contain;
	mask-size: contain;
	-webkit-mask-position: center;
	mask-position: center;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	background: currentColor;
	// color: $color;
	color: inherit;
	-webkit-transition: inherit;
	transition: inherit;
}

@mixin tick {
	-webkit-mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="300px" height="300px" fill="%23fff" viewBox="0 0 300 300" style="enable-background:new 0 0 300 300;" xml:space="preserve"><polygon points="268.3,27.8 112.8,214 26.6,141.5 0,173 117.9,272.2 300,54.3 "/></svg>');
	mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="300px" height="300px" fill="%23fff" viewBox="0 0 300 300" style="enable-background:new 0 0 300 300;" xml:space="preserve"><polygon points="268.3,27.8 112.8,214 26.6,141.5 0,173 117.9,272.2 300,54.3 "/></svg>');
	@include mask_image;
}

@mixin chev {
	-webkit-mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="300px" height="300px" fill="%23040707" viewBox="-155 247 300 300" style="enable-background:new -155 247 300 300;"><polygon points="78.6356201,306.8178101 -5.0166931,390.4367371 -88.6356277,306.8178101 -137, 355.1821899 -5.0166931,487.1821899 127,355.1821899 "/></svg>');
	mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="300px" height="300px" fill="%23040707" viewBox="-155 247 300 300" style="enable-background:new -155 247 300 300;"><polygon points="78.6356201,306.8178101 -5.0166931,390.4367371 -88.6356277,306.8178101 -137, 355.1821899 -5.0166931,487.1821899 127,355.1821899 "/></svg>');
	@include mask_image;
}

@mixin chev_left {
	-webkit-mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="20px" height="20px" viewBox="-481.4169922 271.0341797 20 20" style="enable-background:new -481.4169922 271.0341797 20 20;" fill="%23fff"><path d="M-467.5097046,271.2515869c0.5028076,0,1.0072327,0.1921692,1.3915405,0.5764771 c0.7686462,0.7686462,0.7686462,2.0144958,0,2.7831421l-6.4229736,6.4229736l6.4229736,6.4229736 c0.7686462,0.7686462,0.7686462,2.0144958,0,2.7831421s-2.0144653,0.7686462-2.7831116,0l-7.8145752-7.8145447 c-0.7686157-0.7686462-0.7686157-2.0144958,0-2.7831421l7.8145752-7.8145447 C-468.5169678,271.4437561-468.0125427,271.2515869-467.5097046,271.2515869z"/></svg>');
	mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="20px" height="20px" viewBox="-481.4169922 271.0341797 20 20" style="enable-background:new -481.4169922 271.0341797 20 20;" fill="%23fff"><path d="M-467.5097046,271.2515869c0.5028076,0,1.0072327,0.1921692,1.3915405,0.5764771 c0.7686462,0.7686462,0.7686462,2.0144958,0,2.7831421l-6.4229736,6.4229736l6.4229736,6.4229736 c0.7686462,0.7686462,0.7686462,2.0144958,0,2.7831421s-2.0144653,0.7686462-2.7831116,0l-7.8145752-7.8145447 c-0.7686157-0.7686462-0.7686157-2.0144958,0-2.7831421l7.8145752-7.8145447 C-468.5169678,271.4437561-468.0125427,271.2515869-467.5097046,271.2515869z"/></svg>');
	@include mask_image;
}

@mixin chev_right {
	-webkit-mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="20px" height="20px" viewBox="-481.4169922 271.0341797 20 20" style="enable-background:new -481.4169922 271.0341797 20 20;" fill="%23fff"><path d="M-475.3242798,290.8167725c-0.5028076,0-1.0072327-0.1921387-1.3915405-0.5764771 c-0.7686462-0.7686462-0.7686462-2.0144958,0-2.7831421l6.4229736-6.4229736l-6.4229736-6.4229736 c-0.7686462-0.7686462-0.7686462-2.0144958,0-2.7831421c0.7686157-0.7686462,2.0144653-0.7686462,2.7831116,0l7.8145447,7.8145447 c0.7686462,0.7686462,0.7686462,2.0144958,0,2.7831421l-7.8145447,7.8145447 C-474.3170166,290.6246338-474.8214417,290.8167725-475.3242798,290.8167725z"/></svg>');
	mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="20px" height="20px" viewBox="-481.4169922 271.0341797 20 20" style="enable-background:new -481.4169922 271.0341797 20 20;" fill="%23fff"><path d="M-475.3242798,290.8167725c-0.5028076,0-1.0072327-0.1921387-1.3915405-0.5764771 c-0.7686462-0.7686462-0.7686462-2.0144958,0-2.7831421l6.4229736-6.4229736l-6.4229736-6.4229736 c-0.7686462-0.7686462-0.7686462-2.0144958,0-2.7831421c0.7686157-0.7686462,2.0144653-0.7686462,2.7831116,0l7.8145447,7.8145447 c0.7686462,0.7686462,0.7686462,2.0144958,0,2.7831421l-7.8145447,7.8145447 C-474.3170166,290.6246338-474.8214417,290.8167725-475.3242798,290.8167725z"/></svg>');
	@include mask_image;
}

@mixin eye {
	-webkit-mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100px" height="100px" fill="%23fff" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;"><path d="M61.6,38.4c-3.3-3.3-7.2-5-11.6-5c-1.6,0-3.1,0.2-4.5,0.6c1.6,1.6,2.4,3.5,2.4,5.7 c0,2.2-0.8,4.1-2.4,5.7c-1.6,1.6-3.5,2.5-5.7,2.5c-2.2,0-4.1-0.8-5.7-2.4c-0.4,1.4-0.6,2.9-0.6,4.5c0,4.4,1.7,8.3,5,11.6 c3.3,3.3,7.2,5,11.6,5c4.4,0,8.3-1.7,11.6-5c3.3-3.3,5-7.2,5-11.6C66.6,45.6,64.9,41.7,61.6,38.4z"/><path d="M80.5,25.5c-9.1-6.4-19.1-9.5-30.5-9.5s-21.4,3.2-30.5,9.5C10.5,31.8,4.1,40,0,50c4.1,10,10.5,18.2,19.5,24.5 c9.1,6.4,19.1,9.5,30.5,9.5s21.4-3.6,30.5-9.5C89.5,68.6,95.9,60,100,50C95.9,40,89.5,31.8,80.5,25.5z M67.5,67.5 c-4.5,5-11,7.5-17.5,7.5c-6.5,0-12.5-2.5-17.5-7.5c-5-5-7.5-11-7.5-17.5c0-6.5,3-12.5,7.5-17.6c4.5-5,11-7.5,17.5-7.5 c6.5,0,12.5,3,17.5,7.5c5,4.5,7.5,11,7.5,17.6C75.1,56.5,72.1,62.5,67.5,67.5z"/></svg>');
	mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100px" height="100px" fill="%23fff" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;"><path d="M61.6,38.4c-3.3-3.3-7.2-5-11.6-5c-1.6,0-3.1,0.2-4.5,0.6c1.6,1.6,2.4,3.5,2.4,5.7 c0,2.2-0.8,4.1-2.4,5.7c-1.6,1.6-3.5,2.5-5.7,2.5c-2.2,0-4.1-0.8-5.7-2.4c-0.4,1.4-0.6,2.9-0.6,4.5c0,4.4,1.7,8.3,5,11.6 c3.3,3.3,7.2,5,11.6,5c4.4,0,8.3-1.7,11.6-5c3.3-3.3,5-7.2,5-11.6C66.6,45.6,64.9,41.7,61.6,38.4z"/><path d="M80.5,25.5c-9.1-6.4-19.1-9.5-30.5-9.5s-21.4,3.2-30.5,9.5C10.5,31.8,4.1,40,0,50c4.1,10,10.5,18.2,19.5,24.5 c9.1,6.4,19.1,9.5,30.5,9.5s21.4-3.6,30.5-9.5C89.5,68.6,95.9,60,100,50C95.9,40,89.5,31.8,80.5,25.5z M67.5,67.5 c-4.5,5-11,7.5-17.5,7.5c-6.5,0-12.5-2.5-17.5-7.5c-5-5-7.5-11-7.5-17.5c0-6.5,3-12.5,7.5-17.6c4.5-5,11-7.5,17.5-7.5 c6.5,0,12.5,3,17.5,7.5c5,4.5,7.5,11,7.5,17.6C75.1,56.5,72.1,62.5,67.5,67.5z"/></svg>');
	@include mask_image;
}

@mixin eye_slash {
	-webkit-mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100px" height="100px" fill="%23fff" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;"><path d="M50,25.2c5.9,0,11.3,2.7,15.8,6.8c4.5,4.1,6.8,9.9,6.8,15.8c0,2.9-0.7,5.7-1.8,8.3l13.5,13.5 c7.1-5.7,12.3-13.2,15.8-21.8c-4.1-10-10.5-18.2-19.5-24.5c-9.1-6.4-19.1-9.5-30.5-9.5c-6.6,0-12.7,1.1-18.4,3.2l10,10 C44.2,25.8,47.1,25.2,50,25.2z"/><path d="M90.7,87.5L10.3,7.1l-5.4,5.4l12.4,12.4C9.4,31,3.7,38.6,0,47.8c4.1,10,10.5,18.2,19.5,24.5 c9.1,6.4,19.1,9.5,30.5,9.5c7.3,0,13.9-1.5,20.2-4.1l15.1,15.1L90.7,87.5z M50,70.3c-5.9,0-11.3-2.3-15.8-6.8 c-4.5-4.5-6.8-9.9-6.8-15.8c0-3.6,1-6.9,2.7-10.1l7.1,7.1c-0.3,1-0.4,1.9-0.4,2.9c0,3.4,1.3,6.6,3.9,9.2c2.6,2.6,5.8,3.9,9.2,3.9 c1,0,2-0.1,3-0.3l7.3,7.3C57.1,69.5,53.6,70.3,50,70.3z"/><path d="M63.1,47.8c0-3.4-1.3-6.8-3.9-9.2c-2.6-2.4-5.8-3.9-9.2-3.9c-0.2,0-0.4,0-0.7,0l13.8,13.8 C63.1,48.2,63.1,48,63.1,47.8z"/></svg>');
	mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100px" height="100px" fill="%23fff" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;"><path d="M50,25.2c5.9,0,11.3,2.7,15.8,6.8c4.5,4.1,6.8,9.9,6.8,15.8c0,2.9-0.7,5.7-1.8,8.3l13.5,13.5 c7.1-5.7,12.3-13.2,15.8-21.8c-4.1-10-10.5-18.2-19.5-24.5c-9.1-6.4-19.1-9.5-30.5-9.5c-6.6,0-12.7,1.1-18.4,3.2l10,10 C44.2,25.8,47.1,25.2,50,25.2z"/><path d="M90.7,87.5L10.3,7.1l-5.4,5.4l12.4,12.4C9.4,31,3.7,38.6,0,47.8c4.1,10,10.5,18.2,19.5,24.5 c9.1,6.4,19.1,9.5,30.5,9.5c7.3,0,13.9-1.5,20.2-4.1l15.1,15.1L90.7,87.5z M50,70.3c-5.9,0-11.3-2.3-15.8-6.8 c-4.5-4.5-6.8-9.9-6.8-15.8c0-3.6,1-6.9,2.7-10.1l7.1,7.1c-0.3,1-0.4,1.9-0.4,2.9c0,3.4,1.3,6.6,3.9,9.2c2.6,2.6,5.8,3.9,9.2,3.9 c1,0,2-0.1,3-0.3l7.3,7.3C57.1,69.5,53.6,70.3,50,70.3z"/><path d="M63.1,47.8c0-3.4-1.3-6.8-3.9-9.2c-2.6-2.4-5.8-3.9-9.2-3.9c-0.2,0-0.4,0-0.7,0l13.8,13.8 C63.1,48.2,63.1,48,63.1,47.8z"/></svg>');
	@include mask_image;
}

@mixin info {
	-webkit-mask-image: $info;
	mask-image: $info;
	@include mask_image;
}

@mixin star {
	-webkit-mask-image: $star;
	mask-image: $star;
	@include mask_image;
}

/*
|----------------------------------------------------------------------
|       Media Queries
|----------------------------------------------------------------------
*/

// led ---- 1920
// desktop ---- 1600
// laptop ---- 1368
// ipad ---- 1024
// ipad_sm ---- 991
// mobile ---- 767
// mobile_md ---- 576
// mobile_sm ---- 475
// mobile_xs ---- 375
// mobile_xx ---- 320

@mixin led {
	@media (min-width: 1900px) {
		@content;
	}
}

@mixin lcd {
	@media (min-width: 1760px) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: 1560px) {
		@content;
	}
}

@mixin laptop_xl {
	@media (min-width: 1368px) {
		@content;
	}
}

@mixin laptop {
	@media (max-width: 1368px) {
		@content;
	}
}

@mixin ipad {
	@media (max-width: 1024px) {
		@content;
	}
}

@mixin ipad_sm {
	@media (max-width: 991px) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: 767px) {
		@content;
	}
}

@mixin mobile_md {
	@media (max-width: 576px) {
		@content;
	}
}

@mixin mobile_sm {
	@media (max-width: 475px) {
		@content;
	}
}

@mixin mobile_xs {
	@media (max-width: 375px) {
		@content;
	}
}

@mixin mobile_xx {
	@media (max-width: 320px) {
		@content;
	}
}

/*
|----------------------------------------------------------------------
|       Import Files
|----------------------------------------------------------------------
*/

@import "./assets/scss/_reset";
@import "./assets/scss/_main";



#loading {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	background: #f1f2f3;
	z-index: 9999;
}

#loading.loadingFixed {
	position: fixed;
}

#loading img {

	width: 100px;
	height: 100px;
	margin: auto;
}

#contact form .error {
	display: flex;
	align-items: center;
	color: #ee414c
}

#contact form .error img {
	width: 20px;
	margin-right: 5px;
	object-fit: contain;
}

.phone_btn {
	margin-top: 15px;
}